import React from "react";
import socketIOClient from "socket.io-client";
import qs from "query-string";
import './App.css';
import LastRegisteredPlate from './LastRegisteredPlate';
import LastCheckedPlate from './LastCheckedPlate';
import SocialShareButtons from './SocialShareButtons';

class App extends React.Component {
  constructor(props) {
    super(props);

    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    // check for incoming share urls:
    if(window.location.pathname.match(/^\/s-/)) {
      window.history.pushState(null, null, "/");
    }

    this.state = {
      lastRegisteredPlate: query.renderplate ? query.renderplate : '',
      lastCheckedPlate: '',
      numberOfRegisteredPlatesToday: null,
      showDebug: query.debug !== undefined,
      cleanView: query.cleanview === 'true',
      renderPlate: query.renderplate
    };
  }

  componentDidMount() {
    if(this.state.renderPlate) return;

    fetch("/api/lastregisteredplate")
      .then((res) => res.json())
      .then((data) => this.setState({lastRegisteredPlate: data.plate}));

    fetch("/api/numberofregisteredplatestoday")
      .then((res) => res.json())
      .then((data) => this.setState({numberOfRegisteredPlatesToday: data.numberOfRegisteredPlatesToday}));

    this.listenToSocket();
  }

  listenToSocket() {
    if(this.socket) return; // prevent multiple connections

    this.socket = socketIOClient();

    // some debugging statements concerning socket.io
    this.socket.on('reconnecting', seconds => {
      console.log('reconnecting in ' + seconds + ' seconds');
    });

    this.socket.on('reconnect', () => {
      console.log('reconnected');
    });

    this.socket.on('reconnect_failed', () => {
      console.log('failed to reconnect');
    });

    this.socket.on('connect', () => {
      console.log('socket connected');
    });

    this.socket.on('lastRegisteredPlate', data => {
      this.setState({lastRegisteredPlate: data.plate});
    });

    this.socket.on('numberOfRegisteredPlatesToday', data => {
      this.setState({numberOfRegisteredPlatesToday: data.numberOfRegisteredPlatesToday});
    });

    this.socket.on('lastCheckedPlate', data => {
      this.setState({lastCheckedPlate: data.plate});
    });
  }


  render() {
    if(!window.navigator.userAgent.match(/googlebot|google\.com\/bot\.html|bingbot|slurp|duckduckbot|baiduspider|yandexbot|facebot|applebot/i)) {
      document.title = this.state.lastRegisteredPlate;
    }

    const shareUrl = `https://laatstenummerplaat.be/s-${this.state.lastRegisteredPlate.toLowerCase()}`;
    const mediaUrl = `https://laatstenummerplaat.be/social-image/${this.state.lastRegisteredPlate.toLowerCase()}.png`;

    return (
      <div className="container App">
        <LastRegisteredPlate plate={this.state.lastRegisteredPlate} numberOfRegisteredPlatesToday={this.state.numberOfRegisteredPlatesToday} cleanView={this.state.cleanView} />

        {!this.state.cleanView && <div className="external-link"><a href="https://blog.laatstenummerplaat.be" target="_blank">Blog</a></div>}

        {this.state.showDebug && <LastCheckedPlate plate={this.state.lastCheckedPlate} /> }

        {!this.state.cleanView &&
          <div className="bottom-box">
            <SocialShareButtons shareUrl={shareUrl} mediaUrl={mediaUrl} />
            <br />
            <br />
            <div className="copyright">
              <a href="https://mobilit.belgium.be/nl/weg/inschrijven-en-schrappen" target="_blank" rel="noreferrer nofollow noopener">
                Status nummerplaataanvraag of -registratie opvragen.
              </a>
              <br />
              <br />
              <a href="http://samdecrock.be" className="contact">© Sam Decrock {new Date().getFullYear()}</a>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default App;
