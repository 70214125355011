import React from 'react';

class LastRegisteredPlate extends  React.Component {
	render() {
		return (
			<div className="last-registerd-plate-fullscreen-wrapper">
				<div className="last-registerd-plate">
					<div className="info">Laatst geregistreerde nummerplaat:</div>
					<div className="plate">{this.props.plate}</div>
					{this.props.numberOfRegisteredPlatesToday !== null && <div className="info number-of-registered-plates-today">Er zijn vandaag al {this.props.numberOfRegisteredPlatesToday} nummerplaten geregistreerd.</div>}
				</div>
			</div>
		);
	}
}

export default LastRegisteredPlate;